import React, {useEffect} from "react";
import axios from "axios";
import {Container, Stack} from "@mui/material";
import Carousel from "../components/Carousel";
import AppointmentLine from "../components/AppointmentLine";
import About from "../components/About";
import Repair from "../components/Repair";
import Casco from "../components/Casco";
import Fleet from "../components/Fleet";
import Feedback from "../components/Feedback";
import Gallery from "../components/Gallery";
import Certificate from "../components/Certificate";
import Partner from "../components/Partner";
import {Helmet} from "react-helmet";


function Root(){
    /*useEffect(() => {
        // Itt hajtsd végre az API-hívást
        axios.get('https://jsonplaceholder.typicode.com/users')
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);*/

    return(
        <>
            <Container className="content" maxWidth={false} sx={{paddingX: {xs: 0 },  marginTop: '60px'}}>
                <Stack spacing={0}>
                    <Helmet>
                        <title>Szélvédő Javítás és Csere | Akár még ma | Glassmax</title>
                        <meta name="description" content="Térjen vissza mielőbb az utakra. Szélvédő javítás és csere gyorsan, egyszerűen. Teljeskörű CASCO ügyintézés, 1 év garancia. Több mint 20 év tapasztalat." />
                    </Helmet>
                    <Carousel/>
                    <AppointmentLine/>
                    <About/>
                    <Repair/>
                    <AppointmentLine/>
                    <Casco/>
                    <Fleet/>
                    <AppointmentLine/>
                    <Feedback/>
                    <Gallery/>
                    <Certificate/>
                    <Partner/>
                </Stack>
            </Container>
        </>
    )
}

export default Root